import axios from 'axios';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as toastr from 'toastr';

import { Config } from '../../config';
import { utils } from '../../utils';
import { Spinner } from '../spinner/spinner.component';


export interface EditRoomFormProps extends RouteComponentProps<{ roomId: string; }> { }

export interface EditRoomFormState {
  name: string;
  passphrase: string;
  enableStamp: boolean;
  enableAnonymous: boolean;
  showSpinner: boolean;
}

export class EditRoomForm extends React.Component<EditRoomFormProps, EditRoomFormState> {

  constructor(props: EditRoomFormProps) {
    super(props);
    this.state = {
      name: '',
      passphrase: '',
      enableStamp: false,
      enableAnonymous: false,
      showSpinner: true
    };
    this.handleClickRegisterButton = this.handleClickRegisterButton.bind(this);
  }

  async componentDidMount() {
    try {
      const roomId = this.props.match.params.roomId;
      const response = await axios.get(Config.getInstance().getHost() + `/api/v2/rooms/${ roomId }.json`, await utils.createAuthHeaders());
      this.setState({
        name: response.data.name,
        passphrase: response.data.passphrase,
        enableStamp: response.data.enable_stamp ? true : false,
        enableAnonymous: response.data.enable_anonymous ? true : false,
        showSpinner: false
      });
    } catch (error) {
      await utils.setStatePromise({ showSpinner: false }, this);
      toastr.error('通信中にエラーが発生しました。', '', { closeButton: true });
    }
  }

  async handleClickRegisterButton() {
    if (this.state.showSpinner) {
      return;
    }
    await utils.setStatePromise({ showSpinner: true }, this);
    try {
      const roomId = this.props.match.params.roomId;
      const headers = await utils.createAuthHeaders();
      const params = {
        name: this.state.name,
        passphrase: this.state.passphrase,
        enable_stamp: this.state.enableStamp ? 1 : 0,
        enable_anonymous: this.state.enableAnonymous ? 1 : 0
      };
      await axios.put(Config.getInstance().getHost() + `/api/v2/rooms/${ roomId }.json`, params, headers);

      await utils.setStatePromise({ showSpinner: false }, this);
      this.props.history.push('/');

    } catch(error) {
      await utils.setStatePromise({ showSpinner: false }, this);
      toastr.error('通信中にエラーが発生しました。', '', { closeButton: true });
    }
  }

  render() {
    return (
      <div style={{ padding: '20px' }}>
        
        <div className="field">
          <label className="label">部屋の名前</label>
          <div className="control">
            <input className="input" type="text" placeholder=""
              onChange={ (event) => {
                this.setState({ name: event.currentTarget.value })
              }}
              value={ this.state.name }
            />
          </div>
        </div>

        <div className="field">
          <label className="label">入室パスワードの設定（任意）</label>
          <p>パスワードを設定すると、パスワードを知らない人は入室できなくなります。</p>
          <div className="control">
            <input className="input" type="password" placeholder="password"
              value={ this.state.passphrase }
              onChange={ (event) => {
                this.setState({ passphrase: event.currentTarget.value })
              } }
            />
          </div>
        </div>

        <div className={`field`}>
          <div className={`control`} style={{ }}>
            <label className="checkbox">
              <input type="checkbox"
                checked={ this.state.enableStamp }
                onChange={ () => {
                  this.setState({ enableStamp: !this.state.enableStamp });
                }}
              />
              スタンプ機能を許可する
            </label>
          </div>
        </div>

        <div className={`field`}>
          <div className={`control`} style={{ }}>
            <label className="checkbox">
              <input type="checkbox"
                checked={ this.state.enableAnonymous }
                onChange={ () => {
                  this.setState({ enableAnonymous: !this.state.enableAnonymous });
                }}
              />
              匿名でのメッセージ送信を許可する
            </label>
          </div>
        </div>

        <button className="button is-info" onClick={ this.handleClickRegisterButton }>設定</button>
        { this.state.showSpinner ? <Spinner/> : <></> }
      </div>
    );
  }
}
