exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3WW4At7FnvlLQAw_wpArlT{position:fixed;top:0;left:0;height:100vh;width:100vw;background-color:transparent}", ""]);

// exports
exports.locals = {
	"root": "_3WW4At7FnvlLQAw_wpArlT"
};