import * as React from 'react';
import Picker from 'vanilla-picker';


export interface ReactVanillaPickerState {
  color: string;
}

export interface ReactVanillaPickerProps  {
  defaultColor: string;
  onChangeColor: (color: any) => void;
}

export class ReactVanillaPicker extends React.Component<ReactVanillaPickerProps, ReactVanillaPickerState> {

  private picker: Picker;

  private parent: HTMLDivElement;

  constructor(props: ReactVanillaPickerProps) {
    super(props);
    this.state = {
      color: props.defaultColor
    };
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (!this.parent) {
      return;
    }
    this.picker = new Picker({
      parent: this.parent,
      color: this.state.color
    });
    this.picker.onChange = this.handleChangeColor;
  }

  componentWillUnmount() {
    if (!this.picker) {
      return;
    }
    this.picker.destroy();
  }

  handleChangeColor(event: any) {
    const color = event.rgbaString;
    this.setState({
      color
    }, () => {
      this.props.onChangeColor(color);
    });
  }

  handleClick(event: React.MouseEvent) {
    if (!this.picker) {
      return;
    }
    this.picker.openHandler(event.nativeEvent);
  }

  render() {
    return <div
      ref={ (div) => { this.parent = div; }}
      onClick={ this.handleClick }
      style={{
        background: this.state.color,
        width: '50px',
        height: '25px',
        display: 'inline-block',
        cursor: 'pointer',
        borderColor: '#222',
        borderStyle: 'solid',
        borderWidth: '1px'
      }}
    />
  }
}
