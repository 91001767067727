import * as _ from 'lodash';


export const DEFAULT_CONFIG = JSON.stringify({
    background: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    text: {
      color: '#fff',
      fontSize: 18,
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
});
