const ActionTypes = {

  CHANGE: 'app.*.*',

  CHANGE_MESSAGES: 'app.messages.*',

  CHANGE_CONFIG: 'app.config.*'
};

export default ActionTypes;
