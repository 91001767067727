exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3w2iAiZaIgY9ux8ELuFtOi{position:fixed;bottom:0;background-color:#fff;width:100vw;z-index:1000}._3w2iAiZaIgY9ux8ELuFtOi:hover{transition:.2s linear;opacity:1}._3w2iAiZaIgY9ux8ELuFtOi:focus{transition:.2s linear;opacity:1}._2zvBQXui--al8nVRYBWpaU{width:100%}._1y4rnLQ2OK0q7rg-AMLguT{display:inline-block;width:60px}.S24W6J87xhmaxvuplotZd{position:absolute;right:80px;top:8px}._2XdYRjyBKhc4sy5gY-wVnW{width:120px}", ""]);

// exports
exports.locals = {
	"message-form": "_3w2iAiZaIgY9ux8ELuFtOi",
	"messageForm": "_3w2iAiZaIgY9ux8ELuFtOi",
	"input": "_2zvBQXui--al8nVRYBWpaU",
	"button": "_1y4rnLQ2OK0q7rg-AMLguT",
	"show-stamp": "S24W6J87xhmaxvuplotZd",
	"showStamp": "S24W6J87xhmaxvuplotZd",
	"author": "_2XdYRjyBKhc4sy5gY-wVnW"
};