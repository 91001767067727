import { Auth } from 'aws-amplify';
import * as _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as toastr from 'toastr';

import { Config } from '../../config';
import { utils } from '../../utils';

import { DEFAULT_CONFIG } from '../../objects/default-config';

import { Spinner } from '../spinner/spinner.component';
import { ReactVanillaPicker } from '../react-vanilla-picker/react-vanilla-picker.component';


export interface DisplayConfigProps extends RouteComponentProps<{ roomId: string; }> { }

export interface DisplayConfigState {
  isInitilized: boolean;
  showSpinner: boolean;
  config: string;
}

export class DisplayConfig extends React.Component<DisplayConfigProps, DisplayConfigState> {

  constructor(props: DisplayConfigProps) {
    super(props);
    this.state = {
      isInitilized: false,
      showSpinner: true,
      config: DEFAULT_CONFIG
    };
    this.handleBackgroundBgColor = this.handleBackgroundBgColor.bind(this);
    this.handleChangeTextFontSize = this.handleChangeTextFontSize.bind(this);
    this.handleChangeTextBgColor = this.handleChangeTextBgColor.bind(this);
    this.handleChangeTextColor = this.handleChangeTextColor.bind(this);
    this.handleClickSetDefault = this.handleClickSetDefault.bind(this);
  }

  async componentDidMount() {
    try {
      const config = localStorage.getItem('display_config') ? localStorage.getItem('display_config') : DEFAULT_CONFIG;
      this.setState({
        isInitilized: true,
        showSpinner: false,
        config: config
      });
    } catch (error) {
      utils.setStatePromise({ showSpinner: false }, this);
      toastr.error('通信中にエラーが発生しました。', '', { closeButton: true });
    }
  }

  private async getAccessToken() {
    let accessToken;
    try {
      const session = await Auth.currentSession();
      accessToken = session.getAccessToken().getJwtToken();
    } catch {
      toastr.error('通信中にエラーが発生しました。',  '', { closeButton: true });
    }
    return accessToken;
  }

  async handleBackgroundBgColor(color: string) {
    if (!this.state.isInitilized) {
      return;
    }
    const config = JSON.parse(this.state.config);
    config.background.backgroundColor = color;

    if (Config.getInstance().getIsElectron()) {
      const { roomId } = this.props.match.params;
      (window as any).api.send('change_config', roomId, JSON.stringify(config));
      _.throttle(function () {
        localStorage.setItem('display_config', JSON.stringify(config));
      }, 300)();
    }

    this.setState({
      config: JSON.stringify(config)
    });
  }

  async handleChangeTextFontSize(event: React.ChangeEvent<HTMLInputElement>) {
    if (!this.state.isInitilized) {
      return;
    }
    const config = JSON.parse(this.state.config);
    config.text.fontSize = Number.parseInt(event.target.value, 10);

    if (Config.getInstance().getIsElectron()) {
      const { roomId } = this.props.match.params;
      (window as any).api.send('change_config', roomId, JSON.stringify(config));

      _.throttle(function () {
        localStorage.setItem('display_config', JSON.stringify(config));
      }, 300)();
    }

    this.setState({
      config: JSON.stringify(config)
    });
  }

  async handleChangeTextBgColor(color: string) {
    if (!this.state.isInitilized) {
      return;
    }
    const config = JSON.parse(this.state.config);
    config.text.backgroundColor = color;

    if (Config.getInstance().getIsElectron()) {
      const { roomId } = this.props.match.params;
      (window as any).api.send('change_config', roomId, JSON.stringify(config));

      _.throttle(function () {
        localStorage.setItem('display_config', JSON.stringify(config));
      }, 300)();
    }

    this.setState({
      config: JSON.stringify(config)
    });
  }

  async handleChangeTextColor(color: string) {
    if (!this.state.isInitilized) {
      return;
    }
    const config = JSON.parse(this.state.config);
    config.text.color = color;

    if (Config.getInstance().getIsElectron()) {
      const { roomId } = this.props.match.params;
      (window as any).api.send('change_config', roomId, JSON.stringify(config));

      _.throttle(function () {
        localStorage.setItem('display_config', JSON.stringify(config));
      }, 300)();
    }

    this.setState({
      config: JSON.stringify(config)
    });
  }

  async handleClickSetDefault() {
    if (!window.confirm('初期設定に戻しますか？')) {
      return;
    }
    if (!this.state.isInitilized) {
      return;
    }
    const config = JSON.parse(DEFAULT_CONFIG);

    if (Config.getInstance().getIsElectron()) {
      const { roomId } = this.props.match.params;
      (window as any).api.send('change_config', roomId, JSON.stringify(config));

      _.throttle(function () {
        localStorage.setItem('display_config', JSON.stringify(config));
      }, 300)();
    }

    this.setState({
      config: JSON.stringify(config)
    });
  }

  render() {
    const config = JSON.parse(this.state.config);
    return (
      <div style={{ padding: '20px' }}>
        {
          this.state.isInitilized
            ? <>
            <div className="field">
              <label className="label">背景色</label>
              <div className="control">
                <ReactVanillaPicker
                  defaultColor={ config.background.backgroundColor }
                  onChangeColor={ this.handleBackgroundBgColor }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">コメントのフォントサイズ</label>
              <div className="control" style={{ width: '300px' }}>
                <input className="input" type="number"
                  value={ config.text.fontSize }
                  onChange={ this.handleChangeTextFontSize }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">コメントの文字色</label>
              <div className="control" style={{ width: '300px' }}>
                <ReactVanillaPicker
                  defaultColor={ config.text.color }
                  onChangeColor={ this.handleChangeTextColor }
                />
              </div>
            </div>
            <div className="field">
              <label className="label">コメントの背景色</label>
              <div className="control" style={{ width: '300px' }}>
                <ReactVanillaPicker
                  defaultColor={ config.text.backgroundColor }
                  onChangeColor={ this.handleChangeTextBgColor }
                />
              </div>
            </div>

            <div className="field">
              <div className="control" style={{ width: '300px' }}>
                <button className="button" onClick={ this.handleClickSetDefault }>初期設定に戻す</button>
              </div>
            </div>
            </>
            : <></>
        }
        { this.state.showSpinner ? <Spinner/> : <></> }
      </div>
    );
  }
}
