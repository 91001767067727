import * as React from 'react';

import * as styles from './stamp-modal.component.scss';

import { AppStore } from '../../../../stores/app-store';
import { MessageType } from '../../../../models/message-type';
import { Config } from '../../../../config';


export interface StampModalProps {
  isActive: boolean;
  author: string;
  onClose: () => void;
}

export interface StampModalState {
  isActive: boolean;
  author: string;
}

export class StampModal extends React.Component<StampModalProps, StampModalState> {

  constructor(props: StampModalProps) {
    super(props);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickThumbnail = this.handleClickThumbnail.bind(this);
    this.state = {
      isActive: props.isActive,
      author: props.author
    };
  }

  componentWillReceiveProps(nextProps: StampModalProps) {
    this.setState({
      isActive: nextProps.isActive,
      author: nextProps.author
    });
  }

  handleClickClose() {
    this.props.onClose();
  }

  async handleClickThumbnail(stampId: string) {
    this.props.onClose();
    const message = {
      room_id: Number.parseInt(AppStore.getInstance().getState().roomId, 10),
      display_name: AppStore.getInstance().getState().name,
      text: stampId,
      type: MessageType.STAMP
    };
    AppStore.getInstance()
      .getSocket()
      .emit('client_to_server.messageV2', message);
  }

  render() {
    return (
      <div className={`modal ${ this.state.isActive ? 'is-active' : '' }`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">スタンプ</p>
            <button className="delete" aria-label="close" onClick={ this.handleClickClose }>
            </button>
          </header>
          <section className="modal-card-body">
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('1') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_1.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('2') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_2.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('3') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_3.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('4') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_4.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('5') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_5.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('6') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_6.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('7') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_7.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('8') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_8.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('9') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_9.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('10') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_10.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('11') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_11.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('12') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_12.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('13') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_13.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('14') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_14.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('15') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_15.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('16') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_16.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('17') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_17.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('18') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_18.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('19') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_19.png" }/>
            <img className={`${styles.thumbnail}`} onClick={ () => { this.handleClickThumbnail('20') } } src={ Config.getInstance().getHost() + "/images/stamps/stamp_20.png" }/>
          </section>
        </div>
      </div>
    );
  }
}
