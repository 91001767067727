import { EventEmitter2 } from 'eventemitter2';
import * as $ from 'jquery';


export interface HomeStoreState {
  authenticatedUser: any;
}

export interface actionHandler {
  (storeState: HomeStoreState, payload?: object): void;
}

export class HomeStore {

  private static instance: HomeStore;

  private state: HomeStoreState;

  private eventEmitter: EventEmitter2;

  constructor() {
    this.eventEmitter = new EventEmitter2({ wildcard: true });
    this.state = {
      authenticatedUser: null
    };
  }

  static getInstance(): HomeStore {
    if (!HomeStore.instance) {
      HomeStore.instance = new HomeStore();
    }
    return HomeStore.instance;
  }

  getState(): HomeStoreState {
    return this.state;
  }

  registerHandler(actionType: string, actionHandler: actionHandler) {
    this.eventEmitter.on(actionType, actionHandler);
  }

  removeHandler(actionType: string, actionHandler: actionHandler) {
    this.eventEmitter.off(actionType, actionHandler);
  }

  dispatch(actionType: string, payload: object) {
    this.state = $.extend(this.state, payload);
    this.eventEmitter.emit(actionType, this.state, payload);
  }
}
