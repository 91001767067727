exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1bcUwPhrzUcKRgX7pNmWDR{display:inline-block;height:120px;width:120px;margin:10px;box-sizing:border-box;object-fit:contain}._1bcUwPhrzUcKRgX7pNmWDR:hover{border-style:solid;border-radius:10px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"thumbnail": "_1bcUwPhrzUcKRgX7pNmWDR"
};