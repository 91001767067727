import * as React from 'react';
import { BrowserRouter, HashRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { Config } from '../../config';

import { Room } from '../room/room.component';
import { Header } from '../header/header.component';
import { Authenticator } from '../authenticator/authenticator.component';
import { CreateRoomForm } from '../create-room-form/create-room-form';
import { EditRoomForm } from '../edit-room-form/edit-room-form';
import { QrCode } from '../qr-code/qr-code.component';
import { ElectronBase } from '../electron-base/electron-base.component';
import { DisplayConfig  } from '../display-config/display-config.component';
import { Nico } from '../nico/nico.component';
import { Root } from '../root/root';


export interface AppProps { }

export interface AppState { }

export class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.state = {};
  }

  render() {
    const routes = (
      <Switch>
        <Route path="/" exact={ true }
          render={ (props) => {
            return <Root {...props} />;
          }}
        />

        <Route path="/create-room"
          render={ (props) => {
            return <>
              <Header/>
              <Authenticator>
                <CreateRoomForm {...props}/>
              </Authenticator>
            </>;
        }}/>

        <Route path="/edit-room/:roomId" render={ (props: RouteComponentProps<{ roomId: string; }>) => {
          return <>
            <Header/>
            <Authenticator>
              <EditRoomForm {...props} />
            </Authenticator>
          </>;
        }}/>

        <Route path="/display-config/:roomId" render={ (props: RouteComponentProps<{ roomId: string; }>) => {
          return <Authenticator>
            <DisplayConfig {...props} />
          </Authenticator>
        }}/>

        <Route path="/nico/:roomId" render={ (props: RouteComponentProps<{ roomId: string; }>) => {
          return <>
            <Nico {...props}/>
          </>;
        }}/>

        <Route path="/message-form/:roomId" render={ (props: RouteComponentProps<{ roomId: string; }>) => {
          return <>
            <div>test</div>
          </>;
        }}/>

        <Route path="/qrcode" component={ QrCode }/>

        <Route path="/:roomId" render={ (props: RouteComponentProps<{ roomId: string; }>) => {
            const content = (
              <Room {...props}/>
            );
            if ( ! Config.getInstance().getIsElectron()) {
              return content;
            }
            return <ElectronBase iconColor="rgba(255, 255, 255, 0.5)" color="rgba(0, 0, 0, 0.5)">{ content }</ElectronBase>;
        }}/>
      </Switch>
    );

    return Config.getInstance().getIsElectron()
      ? <HashRouter basename={ '/' }>{ routes }</HashRouter>
      : <BrowserRouter basename={ '/' }>{ routes }</BrowserRouter>;
  }
}
