import axios from 'axios';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Config } from '../../config';
import { utils } from '../../utils';
import { HomeStore } from '../../stores/home-store';
import { Spinner } from '../spinner/spinner.component';


export interface CreateRoomFormProps extends RouteComponentProps { }

export interface CreateRoomFormState {
  showSpinner: boolean;

  name: string;
  passphrase: string;
  enableStamp: boolean;
  enableAnonymous: boolean;
}

export class CreateRoomForm extends React.Component<CreateRoomFormProps, CreateRoomFormState> {

  constructor(props: CreateRoomFormProps) {
    super(props);
    this.state = {
      showSpinner: false,
      name: '',
      passphrase: '',
      enableStamp: false,
      enableAnonymous: false
    };
    this.handleClickRegisterButton = this.handleClickRegisterButton.bind(this);
  }

  async componentDidMount() {

  }

  async handleClickRegisterButton() {
    if (this.state.showSpinner) {
      return;
    }
    await utils.setStatePromise({ showSpinner: true }, this);
    try {
      const headers = await utils.createAuthHeaders();
      const user = HomeStore.getInstance().getState().authenticatedUser;
      const room = {
        name: this.state.name,
        passphrase: this.state.passphrase,
        cognito_user_name: user.username,
        enable_stamp: this.state.enableStamp ? 1 : 0,
        enable_anonymous: this.state.enableAnonymous ? 1 : 0
      };
      await axios.post(Config.getInstance().getHost() + '/api/v2/rooms', room, headers);
      this.props.history.push('/');

    } catch(error) {
      console.error(error);
    } finally {
      await utils.setStatePromise({ showSpinner: false }, this);
    }
  }

  render() {
    return (
      <div style={{ padding: '20px' }}>
        <div className="field">
          <label className="label">部屋の名前</label>
          <div className="control">
            <input className="input" type="text" placeholder=""
              onChange={ (event) => {
                this.setState({ name: event.currentTarget.value })
              } }
              value={ this.state.name }
            />
          </div>
        </div>

        <div className="field">
        <label className="label">入室パスワードの設定（任意）</label>
          <p>パスワードを設定すると、パスワードを知らない人は入室できなくなります。</p>
          <div className="control">
            <input className="input" type="password" placeholder="password"
              value={ this.state.passphrase }
              onChange={ (event) => {
                this.setState({ passphrase: event.currentTarget.value })
              } }
            />
          </div>
        </div>

        <div className={`field`}>
          <div className={`control`} style={{ }}>
            <label className="checkbox">
              <input type="checkbox"
                checked={ this.state.enableStamp }
                onChange={ () => {
                  this.setState({ enableStamp: !this.state.enableStamp });
                }}
              />
              スタンプ機能を許可する
            </label>
          </div>
        </div>

        <div className={`field`}>
          <div className={`control`} style={{ }}>
            <label className="checkbox">
              <input type="checkbox"
                checked={ this.state.enableAnonymous }
                onChange={ () => {
                  this.setState({ enableAnonymous: !this.state.enableAnonymous });
                }}
              />
              匿名でのメッセージ送信を許可する
            </label>
          </div>
        </div>

        <button className="button is-info" onClick={ this.handleClickRegisterButton }>登録</button>
        { this.state.showSpinner ? <Spinner/> : <></> }
      </div>
    );
  }
}
