exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3d7b4Z9r51bavDwwmZFiEG{width:100vw;position:fixed;left:0px}", ""]);

// exports
exports.locals = {
	"viewer": "_3d7b4Z9r51bavDwwmZFiEG"
};