import { AuthState } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from "@aws-amplify/ui-react";
import * as React from 'react';

import ActionTypes from '../../action-types';
import { HomeStore } from '../../stores/home-store';


export interface AuthenticatorProps { }

export interface AuthenticatorState { }

export class Authenticator extends React.Component<AuthenticatorProps, AuthenticatorState> {

  constructor(props: AuthenticatorProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <AmplifyAuthenticator
          initialAuthState={ AuthState.SignUp }
          handleAuthStateChange={ (state, data) => {
            if (state === 'signedin') {
              HomeStore.getInstance().dispatch(ActionTypes.CHANGE, { authenticatedUser: data });
            }
            if (state === 'signup' && !data) {
              HomeStore.getInstance().dispatch(ActionTypes.CHANGE, { authenticatedUser: null });
            } 
          }}
        >
          <AmplifySignIn slot="sign-in" usernameAlias="email"/>
          <AmplifySignUp slot="sign-up" usernameAlias="email"
            formFields={[
              {
                type: "name",
                label: "名前（ハンドルネーム）",
                placeholder: "名前（ハンドルネーム）を入力してください",
                inputProps: { required: true, autocomplete: "name" },
              },
              {
                type: "email",
                inputProps: { required: true, autocomplete: "username" },
              },
              {
                type: "password",
                inputProps: { required: true, autocomplete: "new-password" },
              }
            ]}
          />
          { this.props.children }
        </AmplifyAuthenticator>
        <div style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: '30px',
          background: '#333333',
          color: '#fff',
          padding: '0 10px',
          display: 'flex',
          // justifyContent: 'space-between'
        }}>
          <span style={{ margin: '0 20px 0 0' }}>© PERSOL HOLDINGS CO., LTD.</span>
          <a href='/terms.html' target="_blank" style={{ color: '#fff' }}>利用規約</a>
        </div>
      </>
    );
  }
}
