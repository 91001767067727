import * as React from 'react';
import { Link } from 'react-router-dom';


export interface HeaderProps { }

export interface HeaderState { }

export class Header extends React.Component<HeaderProps, HeaderState> {

  render() {
    return (
      <div style={{ height: '50px', width: '100%', background: 'white' }}>
        <Link to={'/'}
          style={{
            padding: '12px 20px',
            display: 'inline-block'
          }}
        >
          <i className="fa fa-arrow-left"></i>
        </Link>
      </div>
    );
  }
}
