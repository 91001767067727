import * as React from 'react';
import { DraggableArea } from '../draggable-area/draggable-area.component';


export interface ElectronBaseProps {
  color?: string;
  iconColor?: string;
}

export interface ElectronBaseState { }

export class ElectronBase extends React.Component<ElectronBaseProps, ElectronBaseState> {

  constructor(props: ElectronBaseState) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <>
        <DraggableArea color={ this.props.color } iconColor={ this.props.iconColor }/>
        <div style={{
          top: '40px',
          height: 'calc(100vh - 40px)',
          width: '100%',
          position: 'fixed',
          overflow: 'auto'
        }}>
          { this.props.children }
        </div>
      </>
    );
  }
}
