import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as toastr from 'toastr';

import { Config } from '../../config';
import { Home } from '../home/home.component';
import { Authenticator } from '../authenticator/authenticator.component';


export interface RootProps extends RouteComponentProps { }

export interface RootState {
  roomUrl: string;
}

export class Root extends React.Component<RootProps, RootState> {

  constructor(props: RootProps) {
    super(props);
    this.handleClickEnterButton = this.handleClickEnterButton.bind(this);
    this.state = {
      roomUrl: ''
    };
  }

  handleClickEnterButton() {
    const regexp = /https:\/\/www.audience.proto.it-engage.me\/([0-9]+).*/;
    if (!regexp.test(this.state.roomUrl)) {
      toastr.error('入力されたURLの形式が正しくありません。',  '', { closeButton: true });
      return;
    }
    const match = this.state.roomUrl.match(regexp);
    const roomId = match[1];
    (window as any).api.send('msg_render_to_main', 'openRoom', roomId);
  }

  render() {
    return (
      <>
        <h1 className="title" style={{ background: '#333', width: '100%', color: 'white', padding: '20px', marginBottom: '0', height: '80px' }}>
          <div style={{
            display: 'inline-block',
            verticalAlign: 'bottom',
            marginRight: '10px'
          }}>
            <img style={{ width: '36px' }} src="https://www.audience.proto.it-engage.me/images/icon.png"/>
          </div>
          <div style={{
            display: 'inline-block',
            verticalAlign: 'top',
            paddingTop: '2px',
            fontSize: '30px'
          }}>AudienceApp</div>
        </h1>
        { Config.getInstance().getIsElectron()
          ? (
            <div style={{ padding: '20px', height: '64px' }}>
              <div className="columns is-mobile">
                <div className="column is-8">
                  <input className="input" type="text" placeholder="部屋のURLを入力"
                    onChange={ (event) => {
                      this.setState({
                        roomUrl: event.currentTarget.value
                      });
                    } }
                    value={ this.state.roomUrl }
                  />
                </div>
                <div className="column is-2">
                  <button className="button is-info" onClick={ this.handleClickEnterButton }>入室</button>
                </div>
              </div>
            </div>
          )
          : <></>
        }
        <Authenticator>
          <Home {...this.props} />
        </Authenticator>
      </>
    );
  }
}
