exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3coe7BBnQfKSZIG-MyUyBL{width:calc(100% - 160px);height:40px;-webkit-app-region:drag}", ""]);

// exports
exports.locals = {
	"draggable": "_3coe7BBnQfKSZIG-MyUyBL"
};