import * as React from 'react';

import * as styles from './message-form.component.scss';
import { StampModal } from './stamp-modal/stamp-modal.component';
import { MessageType } from '../../../models/message-type';
import { AppStore } from '../../../stores/app-store';
import { Config } from '../../../config';


export interface MessageFormProps {
  name: string;
  enableStamp: boolean;
  enableAnonymous: boolean;
}

export interface MessageFormState {
  message: string;
  isQuestion: boolean;
  showStampModal: boolean
}

export class MessageForm extends React.Component<MessageFormProps, MessageFormState> {

  constructor(props: MessageFormProps) {
    super(props);

    this.state = {
      message: '',
      isQuestion: false,
      showStampModal: false
    };

    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleClickSendMessageButton = this.handleClickSendMessageButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickShowModal = this.handleClickShowModal.bind(this);
  }

  handleChangeMessage(event: React.FormEvent<HTMLInputElement>) {
    this.setState({
      message: event.currentTarget.value
    });
  }

  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const ENTER_KEY = 13;
    if (event.keyCode !== ENTER_KEY) {
      return;
    }
    this.sendMessage();
  }

  handleClickSendMessageButton(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.sendMessage();
  }

  private sendMessage() {
    if ( ! this.state.message) {
      return;
    }
    const message = {
      room_id: Number.parseInt(AppStore.getInstance().getState().roomId, 10),
      display_name: AppStore.getInstance().getState().name,
      text: this.state.message,
      type: this.state.isQuestion ? MessageType.Question : MessageType.NORMAL,
    };
    AppStore.getInstance()
      .getSocket()
      .emit('client_to_server.messageV2', message);
    this.setState({
      isQuestion: false,
      message: ''
    });
  }

  handleClickShowModal() {
    this.setState({
      showStampModal: true
    });
  }

  handleClose() {
    this.setState({
      showStampModal: false
    });
  }

  render() {
    return (
      <div style={{
        position: 'fixed',
        bottom: 0,
        height: '72px',
        background: Config.getInstance().getIsElectron() ? 'transparent' : 'white',
        opacity: Config.getInstance().getIsElectron() ? '0.7' : '1'
      }}>

        <div style={{ top: 0, position: 'relative' }} className={`field has-addons ${styles.messageForm}`}>
          <div className={`control`} style={{
            padding: '6px',
            position: 'absolute',
            right: '22px'
          }}>
            <label className="checkbox">
              <input type="checkbox" checked={ this.state.isQuestion } onChange={ () => { this.setState({ isQuestion: !this.state.isQuestion }); } } />
              質問として送信する
            </label>
          </div>
        </div>

        <div className={`field has-addons ${styles.messageForm}`}>

          <div className={`control ${styles.input}`}>
            <input className="input"
              type="text"
              placeholder="コメントを入力"
              onChange={this.handleChangeMessage}
              onKeyDown={this.handleKeyDown}
              value={this.state.message}/>
          </div>

          { this.props.enableStamp
              ? 
                <div className={`control`}>
                  <button className={"button " + styles.button}
                    onClick={this.handleClickShowModal}>
                    <i className={`fa fa-smile-o`} aria-hidden="true"></i>
                  </button>
                  <StampModal author={ this.props.name } isActive={ this.state.showStampModal } onClose={ this.handleClose }/>
                </div>
              : <></>
          }

          <div className={`control`}>
            <button
              className={ "button is-info " + styles.button }
              onClick={ this.handleClickSendMessageButton }
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
          </div>

        </div>
      </div>
    );
  }
}
