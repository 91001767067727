import * as $ from 'jquery';
import * as _ from 'lodash';
import * as React from 'react';
import * as nl2br from 'react-nl2br';

import * as styles from './line-like.component.scss';

import { AppStore } from '../../../stores/app-store';
import { Message } from '../../../models/message';
import { MessageType } from '../../../models/message-type';
import { Config } from '../../../config';


export interface LineLikeProps {
  messages: Message[];
  textFontSize: number;
  textBackgroundCOlor: string;
  textColor: string;
}

export interface LineLikeState {
  isQuestion: boolean;
  notDisplayAnswered: boolean;
}

export class LineLike extends React.Component<LineLikeProps, LineLikeState> {

  private root?: HTMLDivElement;

  constructor(props: LineLikeProps) {
    super(props);
    this.state = {
      isQuestion: false,
      notDisplayAnswered: false
    };
  }

  componentDidMount() {
    $(this.root).animate({
      scrollTop: $(this.root)[0].scrollHeight
    }, 'normal');
  }

  componentDidUpdate() {
    $(this.root).animate({scrollTop: $(this.root)[0].scrollHeight}, 'normal');
  }

  render() {
    const messages = this.state.isQuestion ? this.props.messages.filter((message) => {
      if ( ! this.state.notDisplayAnswered) {
        return message.type === MessageType.Question;
      }
      return (message.type === MessageType.Question) && (message.answered !== 1);
    }) : this.props.messages;

    const rows = messages.map((message, index) => {
      let content;
      if (message.type === MessageType.STAMP) {
        content = (
          <>
            <div style={{ marginLeft: '100px', left: '100px', borderRadius: '10px' }}>
              <img style={{ height: 100 }}
                src={ Config.getInstance().getHost() + `/images/stamps/stamp_${ message.text }.png` }
              />
            </div>
            <div className={ styles.messageAuthor + ' ' + styles.messageAuthorIcon } style={{ color: this.props.textColor }}>
              { message.display_name }
            </div>
          </>
        );
      } else {
        content = (
          <>
            { message.type === MessageType.Question
                ? <i className={`fa ${ message.answered ? 'fa-check-circle' : 'fa-question-circle' }`}
                    style={{
                      paddingRight: 20,
                      color: message.answered ? '#34A853' : 'white',
                      cursor: Config.getInstance().getIsElectron() ? 'pointer' : 'normal'
                    }}
                    onClick={ () => {
                      if ( ! Config.getInstance().getIsElectron()) {
                        return;
                      }
                      AppStore.getInstance()
                        .getSocket()
                        .emit('client_to_server.change_question_status', message);
                    }}
                  ></i>
                : <></> }
            <div className={ styles.messageAuthor } style={{ color: this.props.textColor }}>{ message.display_name }</div>
            <div className={ styles.messageText }
              style={{ color: this.props.textColor }}>
              { nl2br(message.text) }
            </div>
          </>
        );
      }
      return (
        <div key={index}
          className={`${ styles.entry } ${ styles.entryLeftSide }`}
        >
          <div className={`${ styles.message } ${ styles.messageLeftSide }`}
            style={{
              fontSize: this.props.textFontSize,
              backgroundColor: this.props.textBackgroundCOlor
            }}
          >
            { content }
          </div>
        </div>
      );
    });

    return (
      <div ref={(div) => { this.root = div; }}
        className={`${styles.lineLike}`}
        style={{
          top: Config.getInstance().getIsElectron() ? '80px' : '40px'
        }}
      >
        { rows }
        {/* <div style={{
          position: 'fixed',
          top: Config.getInstance().getIsElectron() ? '80px' : '40px',
          right: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          height: '200px',
          width: '400px',
          color: 'white'
        }}>
        </div> */}
        <div style={{
          position: 'fixed',
          top: Config.getInstance().getIsElectron() ? '40px' : '0',
          left: '0',
          backgroundColor: Config.getInstance().getIsElectron() ? 'transparent' : '#333',
          height: '40px',
          width: '100%',
          color: 'white'
        }}>
          <label className="checkbox" style={{ color: 'white', margin: '10px' }}>
            <input
              style={{
                marginRight: '10px',
              }}
              type="checkbox"
              checked={ this.state.isQuestion }
              onChange={ () => {
                this.setState({ 
                  isQuestion: !this.state.isQuestion,
                  notDisplayAnswered: !this.state.isQuestion ? false : this.state.notDisplayAnswered
                });
              }}
            />
            質問のみ表示
          </label>
          { this.state.isQuestion
            ? 
              <label className="checkbox" style={{ color: 'white', margin: '10px' }}>
                <input
                  style={{ marginRight: '10px' }}
                  type="checkbox"
                  checked={ this.state.notDisplayAnswered }
                  onChange={ () => {
                    this.setState({ notDisplayAnswered: !this.state.notDisplayAnswered })
                  }}
                />
                未解答のみ表示
              </label>
            : <></>
          }
          {/* { Config.getInstance().getIsElectron()
              ? (
              <button className="button"
                style={{
                  borderColor: 'transparent',
                  background: 'transparent',
                  position: 'absolute',
                  // width: '220px',
                  right: '220px',
                  color: 'white'
                }}
                onClick={ () => {
                  // const host = 'https://www.audience.proto.it-engage.me';
                  // const roomId = location.pathname.substr(1);
                  // const roomUrl = host + '/' + roomId;
                  // const url = host + '/qrcode?url=' + encodeURIComponent(roomUrl);
                  // (window as any).api.send('msg_render_to_main', 'newWin', url);
                }}
              >
                <i className={`fa fa-cog`}
                  style={{
                    color: 'white',
                    fontSize: 22,
                    margin: '10px'
                  }}
                ></i> 設定
              </button>
            )
            : <></>
          } */}
          { Config.getInstance().getIsElectron()
              ? (
              <button className="button"
                style={{
                  background: 'transparent',
                  borderColor: 'transparent',
                  position: 'absolute',
                  width: '220px',
                  right: '0',
                  color: 'white'
                }}
                onClick={ () => {
                  const roomUrl = 'https://www.audience.proto.it-engage.me/' + AppStore.getInstance().getState().roomId;
                  const url = 'https://www.audience.proto.it-engage.me/qrcode?url=' + encodeURIComponent(roomUrl);
                  (window as any).api.send('msg_render_to_main', 'openUrl', url, {
                    width: 240,
                    height: 300
                  });
                }}
              >
                <i className={`fa fa-qrcode`}
                  style={{
                    color: 'white',
                    fontSize: 22,
                    margin: '10px'
                  }}
                ></i> 二次元コード表示
              </button>
            )
            : <></>
          }
        </div>
      </div>
    );
  }
}
