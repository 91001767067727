import { Auth } from 'aws-amplify';
import axios from 'axios';
import * as _ from 'lodash';
import * as NicoJS from 'nicojs';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as qs from 'qs';

import * as styles from './nico.component.scss';

import ActionTypes from '../../action-types';
import { Config } from '../../config';
import { utils } from '../../utils';
import { Message } from '../../models/message';
import { AppStore, AppStoreState } from '../../stores/app-store';
import { MessageType } from '../../models/message-type';


export interface NicoProps extends RouteComponentProps<{ roomId: string }> { }

export interface NicoState { }

export class Nico extends React.Component<NicoProps, NicoState> {

  private callbackId: number;

  private nico: any;

  private root: HTMLDivElement;

  private nicoDiv: HTMLDivElement;

  private messages: Message[];

  constructor(props: NicoProps) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
    this.onChangeAppStore = this.onChangeAppStore.bind(this);
    this.state = { };
  }

  async componentDidMount() {
    const accessToken = await this.getAccessToken();

    const { roomId } = this.props.match.params;

    const { data: { hasPassword } } = await axios.get(Config.getInstance().getHost() + `/api/v2/rooms/has-password/${ roomId }.json`);
    let password: string;
    if (hasPassword) {
      const query = qs.parse(location.search.substr(1));
      password = decodeURIComponent(query.p as string);
    }
    const url = `/api/v2/messages/${ roomId }.json${ hasPassword ? ('?p=' + password) : '' }`;
    const { data: messages } = await axios.get(Config.getInstance().getHost() + url);

    // タイトルを更新
    try {
      const { data: roomAttrs } = await axios.get(Config.getInstance().getHost() + `/api/v2/rooms/room-attrs/${ roomId }.json`);
      document.title = `コメント - ${ roomAttrs.name } - AudienceApp`;
    } catch { }

    AppStore.createInstance(roomId, accessToken);
    AppStore.getInstance().registerHandler(ActionTypes.CHANGE_MESSAGES, this.onChangeAppStore);

    this.callbackId = window.requestAnimationFrame(this.handleResize);
    this.messages = messages;

    this.nico = new NicoJS({
      app : this.nicoDiv,
      width: this.root.offsetWidth,
      height: this.root.offsetHeight,
      font_size: 24,
      color: 'black'
    });

    this.nico.listen();

    for (const message of messages) {
      await utils.waitFor(500);
      this.send(message);
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.callbackId);
  }

  send(message: Message) {
    const escaped = _.escape(message.text);
    const sendMessage = message.type === MessageType.STAMP
      ? `<img style="height: 100px;" src="${ Config.getInstance().getHost() + `/images/stamps/stamp_${ escaped }.png` }" >`
      : escaped;
    this.nico.send(sendMessage);
  }

  async getAccessToken() {
    let accessToken;
    try {
      const session = await Auth.currentSession();
      accessToken = session.getAccessToken().getJwtToken();
    } catch { }
    return accessToken;
  }

  async openConfirmPasswordModal(roomId: string, hasPassword: boolean) {
    return await Swal.fire({
      title: '入室パスワードを入力してください。',
      input: 'password',
      inputLabel: 'パスワード',
      inputValue: '',
      showCancelButton: false,
      backdrop: true,
      allowOutsideClick: false,
      inputValidator: async (value) => {
        if (!value) {
          return '入室パスワードを入力してください。'
        }
        try {
          await axios.get(Config.getInstance().getHost() + `/api/v2/messages/${ roomId }.json${ hasPassword ? ('?p=' + value) : '' }`);
        } catch (error) {
          return 'パスワードが正しくありません。';
        }
      }
    });
  }

  handleResize() {
    if (!this.nicoDiv || !this.nico) {
      return;
    }
    const width = this.root.offsetWidth;
    const height = this.root.offsetHeight;
    this.nico.resize(width, height);
    this.callbackId = window.requestAnimationFrame(this.handleResize);
  }

  onChangeAppStore(appStoreState: AppStoreState) {
    const message = appStoreState.messages[appStoreState.messages.length - 1];
    const includes = this.messages.filter((item) => {
      return item.id === message.id;
    }).length > 0;
    if (includes) {
      return;
    }
    this.messages = appStoreState.messages;
    this.send(message);
  }

  render() {
    return (
      <div className={ styles.root } ref={ (div) => { this.root = div; } }>
        <div ref={ (div) => { this.nicoDiv = div; } }></div>
      </div>
    );
  }
}
