export class Config {

  static readonly WebHost: string = 'https://www.audience.proto.it-engage.me';

  private static instance: Config;

  private isElectron: boolean;

  private host: string;

  constructor(isElectron: boolean) {
    this.isElectron = isElectron;
    this.host = isElectron ? Config.WebHost : '';
  }

  static initialize(isElectron: boolean) {
    Config.instance = new Config(isElectron);
  }

  static getInstance() {
    if ( ! Config.instance) {
      throw new Error('Not initialized.');
    }
    return Config.instance;
  }

  getIsElectron() {
    return this.isElectron;
  }

  getHost() {
    return this.host;
  }
}
