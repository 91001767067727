exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3I8ujl_NLBlg3wODdq1229{position:fixed;display:inline-block;left:0;width:100vw;height:calc(100% - 112px);overflow:auto;text-align:left;overflow-scrolling:touch;-webkit-overflow-scrolling:touch}._3I8ujl_NLBlg3wODdq1229 ._1_r-yP6IveBQ41xHNoy0K-{position:relative;margin:12px 10px}._3I8ujl_NLBlg3wODdq1229 ._1_r-yP6IveBQ41xHNoy0K- .wVdR2kIsRvn24qeFYx5KY{border-radius:15px;display:inline-block;position:relative;width:auto;max-width:calc(100% - 20px);padding:5px 20px;color:#fff;font-size:18px;line-height:24px;word-wrap:break-word}._3I8ujl_NLBlg3wODdq1229 ._1_r-yP6IveBQ41xHNoy0K- ._2_nUJB622B_O6gTIE8mVkP{text-align:left;left:8px}._3I8ujl_NLBlg3wODdq1229 ._1_r-yP6IveBQ41xHNoy0K- ._3DKA8dje01pqFx3LY-dOOG{background-color:#00da00;text-align:right;right:8px}._3I8ujl_NLBlg3wODdq1229 ._1_r-yP6IveBQ41xHNoy0K- ._2r6NMrN01Z0axq6TFLvJoU{display:inline;vertical-align:top}._3I8ujl_NLBlg3wODdq1229 ._1_r-yP6IveBQ41xHNoy0K- ._3Lg44IatPE-sOO5l6Mgl8l{display:inline-block;font-weight:bold;margin-right:10px;max-width:100px}._3I8ujl_NLBlg3wODdq1229 ._1_r-yP6IveBQ41xHNoy0K- .rSu27tzo-0czAcpsZsxWf{position:absolute;left:10px;top:10px}._3I8ujl_NLBlg3wODdq1229 ._15p0w8wxd9YD6aTiH32nio{text-align:left}._3I8ujl_NLBlg3wODdq1229 .GPjjcBO12nt24-Yuh4xQ3{text-align:right}._3I8ujl_NLBlg3wODdq1229 .GPjjcBO12nt24-Yuh4xQ3::before{content:\"\";position:absolute;top:35%;right:-10px;margin-top:-6px;border:6px solid transparent;border-left:15px solid #00da00}", ""]);

// exports
exports.locals = {
	"line-like": "_3I8ujl_NLBlg3wODdq1229",
	"lineLike": "_3I8ujl_NLBlg3wODdq1229",
	"entry": "_1_r-yP6IveBQ41xHNoy0K-",
	"message": "wVdR2kIsRvn24qeFYx5KY",
	"message--left-side": "_2_nUJB622B_O6gTIE8mVkP",
	"messageLeftSide": "_2_nUJB622B_O6gTIE8mVkP",
	"message--right-side": "_3DKA8dje01pqFx3LY-dOOG",
	"messageRightSide": "_3DKA8dje01pqFx3LY-dOOG",
	"message--text": "_2r6NMrN01Z0axq6TFLvJoU",
	"messageText": "_2r6NMrN01Z0axq6TFLvJoU",
	"message--author": "_3Lg44IatPE-sOO5l6Mgl8l",
	"messageAuthor": "_3Lg44IatPE-sOO5l6Mgl8l",
	"message--author__icon": "rSu27tzo-0czAcpsZsxWf",
	"messageAuthorIcon": "rSu27tzo-0czAcpsZsxWf",
	"entry--left-side": "_15p0w8wxd9YD6aTiH32nio",
	"entryLeftSide": "_15p0w8wxd9YD6aTiH32nio",
	"entry--right-side": "GPjjcBO12nt24-Yuh4xQ3",
	"entryRightSide": "GPjjcBO12nt24-Yuh4xQ3"
};