import * as React from 'react';
import * as qs from 'qs';
import * as QRCode from 'qrcode';


export interface QrCodeProps { }

export interface QrCodeState  { }

export class QrCode extends React.Component<QrCodeProps, QrCodeState> {

  private canvas: HTMLCanvasElement;

  constructor(props: QrCodeProps) {
      super(props);
      this.state = {};
  }

  componentDidMount() {
    if (!this.canvas || !location.search || !location.search.substr(1)) {
      return;
    }
    const query = qs.parse(location.search.substr(1));
    const url = decodeURIComponent(query.url as string);
    QRCode.toCanvas(this.canvas, url, { width: 200 }, function (error) {
      if (error) {
        console.error(error);
      }
    });
  }

  render () {
    return (
      <div style={{ padding: 20, width: '100%', height: '100%' }}>
        <canvas ref={ (canvas) => { this.canvas = canvas; } }></canvas>
      </div>
    );
  }
}
