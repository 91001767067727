import { EventEmitter2 } from 'eventemitter2';
import * as $ from 'jquery';
import * as SocketIoClient from 'socket.io-client';

import { Config } from '../config';
import ActionTypes from '../action-types';
import { Message } from '../models/message';


export interface AppStoreState {
  roomId: string;
  name: string;
  messages: Message[];
  config: string;
  password: string;
}

export interface actionHandler {
  (storeState: AppStoreState, payload?: object): void;
}

export class AppStore {

  private static instance: AppStore;

  private readonly socket: SocketIoClient.Socket;

  private state: AppStoreState;

  private eventEmitter: EventEmitter2;

  constructor(roomId: string, acessToken?: string) {
    this.eventEmitter = new EventEmitter2({ wildcard: true });

    this.socket = SocketIoClient.io(Config.getInstance().getHost() +  '/', {
      forceNew: true,
      auth: {
        authorization: acessToken ? acessToken : ''
      },
      transports: [ 'websocket', 'polling' ]
    });

    this.socket.on(`server_to_client.message.${ roomId }`, this.onReceiveData.bind(this));

    this.state = {
      roomId: roomId,
      name: '',
      messages: [],
      config: '',
      password: ''
    };
  }

  static createInstance(roomId: string, accessToken?: string) {
    if (AppStore.instance) {
      return;
    }
    AppStore.instance = new AppStore(roomId, accessToken);
    return AppStore.instance;
  }

  static getInstance(): AppStore {
    if (!AppStore.instance) {
      throw new Error('Instance does not created.');
    }
    return AppStore.instance;
  }

  getSocket(): SocketIoClient.Socket {
    return this.socket;
  }

  getState(): AppStoreState {
    return this.state;
  }

  registerHandler(actionType: string, actionHandler: actionHandler) {
    this.eventEmitter.on(actionType, actionHandler);
  }

  removeHandler(actionType: string, actionHandler: actionHandler) {
    this.eventEmitter.off(actionType, actionHandler);
  }

  dispatch(actionType: string, payload: object) {
    this.state = $.extend(this.state, payload);
    this.eventEmitter.emit(actionType, this.state, payload);
  }

  private onReceiveData(data: any): void {
    this.dispatch(ActionTypes.CHANGE_MESSAGES, { messages: data });
  }
}
