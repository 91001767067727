import * as React from 'react';
import * as styles from './spinner.component.scss';


export interface SpinnerProps { }

export interface SpinnerState { }

export class Spinner extends React.Component<SpinnerProps, SpinnerState> {

  render() {
    return (
      <div className={ styles.spinner_background }>
        <div className={ styles.spinner_container }>
          <div className={ styles.spinner }></div>
        </div>
      </div>
    );
  }
}
