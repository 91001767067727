import { Auth } from 'aws-amplify';
import axios from 'axios';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';

import * as styles from './room.component.scss';

import ActionTypes from '../../action-types';
import { Config } from '../../config';
import { utils } from '../../utils';

import { AppStore, AppStoreState } from '../../stores/app-store';
import { DEFAULT_CONFIG } from '../../objects/default-config';
import { Message } from '../../models/message';

import { LineLike } from './line-like/line-like.component';
import { MessageForm } from './message-form/message-form.component';


export interface RoomProps extends RouteComponentProps<{ roomId: string }> { }

export interface RoomState  {
  name: string,
  messages: Message[];
  config: string;
  enableStamp: boolean;
  enableAnonymous: boolean;
}

export class Room extends React.Component<RoomProps, RoomState> {

  private timer: NodeJS.Timer | null;

  constructor(props: RoomProps) {
    super(props);
    this.state = {
      name: '',
      messages: [],
      config: '',
      enableStamp: false,
      enableAnonymous: false
    };
    this.timer = null;
    this.onInterval = this.onInterval.bind(this);
    this.onChangeRoomConfig = this.onChangeRoomConfig.bind(this);
    this.onChangeAppStore = this.onChangeAppStore.bind(this);
  }

  async componentDidMount() {
    this.timer = setInterval(this.onInterval, 5000);

    let accessToken;
    try {
      const session = await Auth.currentSession();
      accessToken = session.getAccessToken().getJwtToken();
    } catch { }

    const { roomId } = this.props.match.params;
    if (Config.getInstance().getIsElectron()) {
      (window as any).api.on(`change_config.${ roomId }`, this.onChangeRoomConfig);
    }
    AppStore.createInstance(roomId, accessToken);
    AppStore.getInstance().registerHandler(ActionTypes.CHANGE_MESSAGES, this.onChangeAppStore);

    const { data: { hasPassword } } = await axios.get(Config.getInstance().getHost() + `/api/v2/rooms/has-password/${ roomId }.json`);
    const { data: roomAttrs } = await axios.get(Config.getInstance().getHost() + `/api/v2/rooms/room-attrs/${ roomId }.json`);

    document.title = `${ roomAttrs.name } - AudienceApp`;

    let password: string = '';
    if (hasPassword) {
      const result = await Swal.fire({
        title: '入室パスワードを入力してください。',
        input: 'password',
        inputLabel: 'パスワード',
        inputValue: '',
        showCancelButton: false,
        backdrop: true,
        allowOutsideClick: false,
        inputValidator: async (value) => {
          if (!value) {
            return '入室パスワードを入力してください。'
          }
          try {
            await axios.get(Config.getInstance().getHost() + `/api/v2/messages/${ roomId }.json${ hasPassword ? ('?p=' + value) : '' }`);
          } catch (error) {
            return 'パスワードが正しくありません。';
          }
        }
      });
      password = result.value;
    }

    const url = `/api/v2/messages/${ roomId }.json${ hasPassword ? ('?p=' + password) : '' }`;
    const { data: messages } = await axios.get(Config.getInstance().getHost() + url);

    // if (!Config.getInstance().getIsElectron()) {
      const { value: name } = await Swal.fire({
        title: '名前を入力してください',
        input: 'text',
        inputValue: '',
        showCancelButton: roomAttrs.enable_anonymous ? true : false,
        backdrop: true,
        allowOutsideClick: roomAttrs.enable_anonymous ? true : false,
        inputValidator: (value) => {
          if ( ! value) {
            return 'なまえ入力してください。'
          }
        }
      });
      AppStore.getInstance().dispatch(ActionTypes.CHANGE, {
        name: name,
        password: password
      });
    // }

    const config = localStorage.getItem('display_config') ? localStorage.getItem('display_config') : DEFAULT_CONFIG;
    await utils.setStatePromise({
      messages: messages,
      config: config,
      enableStamp: roomAttrs.enable_stamp ? true : false,
      enableAnonymous: roomAttrs.enable_anonymous ? true : false
    }, this);
  }

  async componentWillUnmount() {
    await Swal.close();
    (AppStore.getInstance().getSocket() as any).removeAllListeners();
    AppStore.getInstance().removeHandler(ActionTypes.CHANGE_MESSAGES, this.onChangeAppStore);

    if (this.timer) {
      clearInterval(this.timer);
    }

    if (Config.getInstance().getIsElectron()) {
      const { roomId } = this.props.match.params;
      (window as any).api.removeListener(`change_config.${ roomId }`, this.onChangeRoomConfig);
    }
  }

  onInterval() {
    AppStore.getInstance()
      .getSocket()
      .emit('client_to_server.reconnect', 'reconnect');
  }

  onChangeAppStore(appStoreState: AppStoreState) {
    this.setState({
      messages: appStoreState.messages
    });
  }

  onChangeRoomConfig(sender: any, config: string) {
    this.setState({ 
      config
    });
  }

  render() {
    const config = this.state.config && Config.getInstance().getIsElectron() ? JSON.parse(this.state.config) : JSON.parse(DEFAULT_CONFIG);
    return (
      <div className={ styles.viewer }
        style={{
          backgroundColor: Config.getInstance().getIsElectron() ? config.background.backgroundColor : 'white',
          height: 'calc(100vh - 40px)',
          top: '40px',
        }}
      >
        <LineLike
          messages={ this.state.messages }
          textFontSize={ config.text.fontSize }
          textBackgroundCOlor={ config.text.backgroundColor }
          textColor={ Config.getInstance().getIsElectron() ? config.text.color : '#333' }
        />
        <MessageForm name={ this.state.name }
          enableStamp={ this.state.enableStamp }
          enableAnonymous={ this.state.enableAnonymous }
        />
      </div>
    );
  }
}
