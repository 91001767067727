import { Auth } from 'aws-amplify';
import * as React from 'react';


export const utils = {

    setStatePromise: function (state: any, component: React.Component) {
        return new Promise<void>((resolve) => {
            component.setState(state, () => {
                resolve();
            });
        });
    },

    createAuthHeaders: async function () {
        const session = await Auth.currentSession();
        const jwtToken = session.getAccessToken().getJwtToken();
        const headers = {
          headers: { authorization: jwtToken }
        };
        return headers;
    },

    waitFor: async function (ms: number): Promise<void> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    }
};
