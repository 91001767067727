import Amplify, { I18n } from 'aws-amplify';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Config } from './config';
import awsmobile from './aws-exports';
import { vocabularies } from './vocabularies';

import { App } from './components/app/app.component';


Config.initialize(false);

I18n.putVocabularies(vocabularies);
I18n.setLanguage('ja');

Amplify.configure(awsmobile);

ReactDOM.render(<App/>, document.getElementById('app'));
