import * as React from 'react';
import * as styles from './draggable-area.component.scss';
import { AppStore } from '../../stores/app-store';


export interface DraggableAreaState {
  enableNico: boolean;
}

export interface DraggableAreaProps {
  color?: string;
  iconColor?: string;
}

export class DraggableArea extends React.Component<DraggableAreaProps, DraggableAreaState> {

  constructor(props: DraggableAreaProps) {
    super(props);
    this.state = {
      enableNico: false
    };
  }

  render() {
    const color = this.props.color ? this.props.color : '#DEE1E6';
    return (
      <div style={{
        background: color,
        position: 'fixed',
        top: 0,
        width: '100%'
      }}>
        <div className={ styles.draggable } style={{ position: 'relative', padding: '4px' }}></div>

        <button className="button"
          onClick={ () => {
            const roomId = AppStore.getInstance().getState().roomId;
            const password = AppStore.getInstance().getState().password;
            (window as any).api.send('msg_render_to_main', 'openNico', roomId, password);
            this.setState({
              enableNico: !this.state.enableNico
            });
          }}
          style={{
            background: 'transparent',
            borderColor: 'transparent',
            position: 'absolute',
            width: '40px',
            height: '40px',
            top: '0',
            right: '120px',
          }}
        >
          <i className={`fa ${ this.state.enableNico ? 'fa-comment-slash' : 'fa-commenting' }`}
            style={{
              color: this.props.iconColor ? this.props.iconColor : 'black',
              fontSize: 22,
          }}></i>
        </button>

        <button className="button"
          onClick={ () => {
            const roomId = AppStore.getInstance().getState().roomId;
            (window as any).api.send('msg_render_to_main', 'openConfig', roomId);
          }}
          style={{
            background: 'transparent',
            borderColor: 'transparent',
            position: 'absolute',
            width: '40px',
            height: '40px',
            top: '0',
            right: '80px',
          }}
        >
          <i className={`fa fa-cog`}
            style={{
              color: this.props.iconColor ? this.props.iconColor : 'black',
              fontSize: 22,
            }}>
          </i>
        </button>
        
        <button className="button"
          onClick={ () => {
            const roomId = AppStore.getInstance().getState().roomId;
            (window as any).api.send('msg_render_to_main', 'minimizeRoom', roomId);
          }}
          style={{
            background: 'transparent',
            borderColor: 'transparent',
            position: 'absolute',
            width: '40px',
            height: '40px',
            top: '0',
            right: '40px',
          }}
        >
          <i className={`fa fa-window-minimize`}
            style={{
              color: this.props.iconColor ? this.props.iconColor : 'black',
              fontSize: 22,
          }}></i>
        </button>

        <button className="button"
          onClick={ () => {
            const roomId = AppStore.getInstance().getState().roomId;
            (window as any).api.send('msg_render_to_main', 'closeRoom', roomId);
          }}
          style={{
            background: 'transparent',
            borderColor: 'transparent',
            position: 'absolute',
            width: '40px',
            height: '40px',
            top: '0',
            right: '0',
          }}
        >
          <i className={`fa fa-close`}
            style={{
              color: this.props.iconColor ? this.props.iconColor : 'black',
              fontSize: 22
            }}
          ></i>
        </button>

      </div>
    );
  }
}
