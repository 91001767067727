import { AmplifySignOut } from '@aws-amplify/ui-react';
import axios from 'axios';
import * as React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as toastr from 'toastr';

import ActionTypes from '../../action-types';
import { Config } from '../../config';
import { utils } from '../../utils';

import { Room } from '../../models/room';
import { HomeStore, HomeStoreState } from '../../stores/home-store';

import { Spinner } from '../spinner/spinner.component';


export interface CopyInputState { }

export interface CopyInputProps {
  text: string;
}

export class CopyInput extends React.Component<CopyInputProps, CopyInputState> {

  private input: HTMLInputElement;

  constructor(props: CopyInputProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={`field has-addons`}>
        <div className={`control`} style={{ width: '100%' }}>
          <input ref={ (input) => { this.input = input; }} className="input" type="text" readOnly={true} defaultValue={ this.props.text }/>
        </div>
        <div className={`control`}>
          <a href="#" className={"button is-info"} onClick={ () => {
              if ( ! this.input) {
                return;
              }
              this.input.select();
              document.execCommand('copy');
              toastr.success('URLをコピーしました。', '', { closeButton: true });
          }}>
            <i className="fa fa-clipboard" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    );
  }
}


export interface HomeProps extends RouteComponentProps { }

export interface HomeState  {
  showSpinner: boolean,
  user: any;
  rooms: Room[];
}

export class Home extends React.Component<HomeProps, HomeState> {

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      showSpinner: true,
      user: null,
      rooms: []
    };
    this.handleClickDeleteButton = this.handleClickDeleteButton.bind(this);
    this.onAuthenticatedUserChanged = this.onAuthenticatedUserChanged.bind(this);
  }

  async componentDidMount() {
    try {
      const user = HomeStore.getInstance().getState().authenticatedUser;
      if ( ! user) {
        this.setState({
          showSpinner: false,
          user: user,
        });
      } else {
        const headers = await utils.createAuthHeaders();
        const response = await axios.get(Config.getInstance().getHost() + '/api/v2/rooms/my-rooms.json', headers);
        this.setState({
          showSpinner: false,
          user: user,
          rooms: response.data
        });
      }
    } catch (error) {
      await utils.setStatePromise({
        showSpinner: false,
      }, this);
      toastr.error('通信中にエラーが発生しました。ブラウザをリロードするか、クライアントソフトを再起動してください。');
    }

    HomeStore.getInstance().registerHandler(ActionTypes.CHANGE, this.onAuthenticatedUserChanged);
  }

  async componentWillUnmount() {
    HomeStore.getInstance().removeHandler(ActionTypes.CHANGE, this.onAuthenticatedUserChanged);
    await Swal.close();
  }

  async handleClickDeleteButton(roomId: number) {
    if (this.state.showSpinner) {
      return;
    }
    const { isConfirmed } = await Swal.fire({
      title: 'この部屋を削除しますか？',
      showCancelButton: true,
      backdrop: true
    });
    if (!isConfirmed) {
      return;
    }
    await utils.setStatePromise({ showSpinner: true }, this);
    const headers =  await utils.createAuthHeaders();
    await axios.delete(Config.getInstance().getHost() + `/api/v2/rooms/${ roomId }.json`, headers);
    const response = await axios.get(Config.getInstance().getHost() + '/api/v2/rooms/my-rooms.json', headers);
    this.setState({
      showSpinner: false,
      rooms: response.data
    });
  }

  async onAuthenticatedUserChanged(storeState: HomeStoreState) {
    await utils.setStatePromise({
      showSpinner: true,
    }, this);
    if ( ! storeState.authenticatedUser) {
      this.setState({
        showSpinner: false,
        user: storeState.authenticatedUser,
        rooms: []
      });
      return;
    }
    const headers = await utils.createAuthHeaders();
    const response = await axios.get(Config.getInstance().getHost() + '/api/v2/rooms/my-rooms.json', headers);
    this.setState({
      showSpinner: false,
      user: storeState.authenticatedUser,
      rooms: response.data
    });
  }

  render() {
    const rooms = this.state.rooms.map((room, index) => {
      return (
        <div key={ index } style={{ padding: 0, margin: '0px', boxSizing: 'border-box' }} className="column is-one-quarter">
          <div className="card" style={{ margin: '10px' }}>
            <div style={{ padding: '14px' }} className="card-content">
              <div className="content">
                <p className="title">
                  { Config.getInstance().getIsElectron()
                    ? <a href="#" style={{ fontSize: '28px', fontWeight: 'bold' }}
                        onClick={ (event) => {
                          event.preventDefault();
                          (window as any).api.send('msg_render_to_main', 'openRoom', room.id);
                        }}
                      >
                        <i className="fa fa-external-link" style={{ marginRight: '10px' }} aria-hidden="true"></i>
                        { room.name }
                      </a>
                    : <a style={{ fontSize: '28px', fontWeight: 'bold' }} href={`${ Config.getInstance().getHost() }/${ room.id }`} target='_blank'>{ room.name }</a>
                  }
                </p>
                <div className="subtitle">
                  <CopyInput key={ index } text={ 'https://www.audience.proto.it-engage.me' + '/' + room.id }/>
                  { Config.getInstance().getIsElectron()
                    ? (
                    <>
                      <a style={{ fontSize: '14px' }} href="#"
                        onClick={ (event) => {
                          event.preventDefault();
                          const roomUrl = 'https://www.audience.proto.it-engage.me/' + room.id;
                          const url = 'https://www.audience.proto.it-engage.me/qrcode?url=' + encodeURIComponent(roomUrl);
                          (window as any).api.send('msg_render_to_main', 'openUrl', url, { 
                            width: 240,
                            height: 340
                          });
                        }}
                      >
                        <i className={`fa fa-qrcode`}
                          style={{
                            fontSize: 14,
                            marginRight: '4px'
                          }}
                        ></i> 二次元コード表示
                      </a>                    
                    </>
                  )
                  : <a style={{ fontSize: '14px' }} target="_blank" href={ 'https://www.audience.proto.it-engage.me/qrcode?url=' + encodeURIComponent('https://www.audience.proto.it-engage.me/' + room.id)} >
                      <i className={`fa fa-qrcode`}
                        style={{
                          fontSize: 14,
                          marginRight: '4px'
                        }}
                      ></i> 二次元コード表示
                    </a>
                }
                </div>

              </div>
            </div>
            <footer className="card-footer">
              <Link className="card-footer-item" to={ '/edit-room/' + room.id }>編集</Link>
              <a href="#" className="card-footer-item"
                onClick={ () => {
                  this.handleClickDeleteButton(room.id);
                }}>
                削除
              </a>
            </footer>

          </div>
        </div>
      );
    });

    return (
      <div style={{  }}>
        <div style={{ padding: '20px 10px' }}>
          <Link to={ '/create-room' }><button className="button is-info">ルームをあたらしく作る</button></Link>
          <div style={{
            position: 'fixed',
            top: '14px',
            right: '20px',
            width: '150px',
            display: 'inline-block'
          }}>
            <AmplifySignOut/>
          </div>
        </div>
        <div className={`columns is-multiline`}
          style={{
            height: Config.getInstance().getIsElectron() ? 'calc(100vh - 308px)' : 'calc(100vh - 244px)',
            padding: '20px',
            overflow: 'auto'
          }}
        >
          { !this.state.showSpinner && rooms.length === 0
            ? <div style={{ padding: '20px' }}>まだMTGルームは作成されていません</div>
            : rooms
          }
        </div>
        { this.state.showSpinner ? <Spinner/> : <></> }
      </div>
    );
  }
}
